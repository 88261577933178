@font-face {
    font-family: "Gilroy-Bold";
    src: local("Gilroy-Bold"),
        url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
    font-weight: bold;
}

/* Roboto Regular */
@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"),
        url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"),
        url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins-Bold";
    src: local("Poppins-Bold"),
        url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
}

html,
body,
#root,
.app,
.content {
    width: 100%;
    display: flex;
    font-family: "Gilroy-Bold", sans-serif !important;
}

.app {
    display: flex;
    position: relative;
}

@font-face {
    font-family: "Gilroy-Bold";
    src: local("Gilroy-Bold"),
        url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
    font-weight: bold;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1 {
    font-family: "Poppins-Bold" !important;
    font-size: 60px;
    font-weight: 600;
    line-height: calc(78 / 70);
    letter-spacing: -0.2px;
  }
  
  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.2;
  }
  
  h3 {
    font-size: 42px;
    line-height: 1.2;
  }
  
  h4 {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.5;
  }
  
  h5 {
    font-size: 20px;
    font-weight: 600;
  }
  
  h6 {
    font-size: 18px;
  }
